
import { Component, Prop, Vue, Watch } from "vue-property-decorator";
import { TypeaheadModel } from "../models/typeahead.model";
import { debounce } from "ts-debounce";
import { applicationService } from "@/services/application.service";
@Component
export default class Typeahead extends Vue {
  @Prop() public label!: string;
  @Prop() public searchText!: string;
  @Prop({ default: [] }) public data!: TypeaheadModel[];
  @Prop() public plcHold!: string;
  @Prop({ default: false }) public sortByName!: boolean;
  @Prop() public fetchUrl!: string;
  @Prop({ default: "sm" }) public size!: string;
  @Prop({ default: 2 }) public minMatchingChars!: number;
  @Prop({ default: 10 }) public maxMatches!: number;
  @Prop() public selectedItem!: any;

  public query = "";
  public suggestions: TypeaheadModel[] = [];

  get list() {
    let options: TypeaheadModel[] = [];
    if (this.data && this.data.length > 0 && !this.fetchUrl) {
      options = this.sortItems(this.data);
    } else if (this.suggestions && this.suggestions.length > 0) {
      options = this.sortItems(this.suggestions);
    }
    if (!options || options.length <= 0) {
      options = [new TypeaheadModel(-1, "No Results", "","", null, null, "")];
    }
    return options;
  }

  created() {
    console.log("Inside Typeahead component created function");
    this.query = this.searchText;
    console.log(this.query);
    console.log(this.selectedItem);
    console.log(this.fetchUrl);
    console.log(
      "Data in Next Line shows: Typeahead.vue.applicationService.addApiToUrl"
    );
    console.log(applicationService.addApiToUrl);
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    // const ipVal: any = this.$refs.typeaheadSearchBar;
    // ipVal.inputValue = this.query;
  }

  async getItems() {
    if (this.fetchUrl) {
      const url = `${applicationService.addApiToUrl}/${this.fetchUrl}${this.query}`;
      //const url = `${this.fetchUrl}${this.query}`;

      fetch(url) //`${this.addApiToUrl}/newapplication/getAllCenterStaff?q=${this.query}`
        .then((response) => {
          return response.json();
        })
        .then((result) => {
          this.suggestions = result.result;
        });
    }
  }

  lookupItem = debounce(() => {
    this.getItems();
  }, 500);

  backgroundVariantResolver(item: TypeaheadModel) {
    if (item?.id) {
      const id = item.id;
      return id % 2 == 0 ? "bg-light" : "bg-dark";
    } else {
      return "bg-light";
    }
  }

  itemSelected($event: Event) {
    const chosenItem: any = $event;
    console.log(
      `selected item from typeahead: Id - ${chosenItem.id}; Text - ${chosenItem.text}; Type - ${chosenItem.type}`
    );
    if (chosenItem.id && chosenItem.text) {
      const selectedModel = new TypeaheadModel(
        chosenItem?.id,
        chosenItem?.text,
        chosenItem?.type,
        chosenItem?.enityType,
        chosenItem?.lat,
        chosenItem?.lng,
        chosenItem?.profileType
      );
      this.$emit("typeaheadItemSelected", selectedModel);
    }
  }

  sortItems(arr: TypeaheadModel[]): TypeaheadModel[] {
    let items: TypeaheadModel[] = [];
    if (this.sortByName) {
      items = arr.sort((a, b) => {
        const nameA = a.text.toUpperCase();
        const nameB = b.text.toUpperCase();
        if (nameA < nameB) {
          return -1;
        }
        if (nameA > nameB) {
          return 1;
        }
        return 0;
      });
    } else {
      items = arr.sort((a, b) => {
        if (a.id && b.id) {
          return a.id - b.id;
        } else {
          return -1;
        }
      });
    }
    return items;
  }

  @Watch("query", { deep: true })
  private onQueryChanged() {
    if (this.query) {
      this.lookupItem.call(this.query);
    } else {
      this.$emit("typeaheadItemEmpty");
    }
  }
}
