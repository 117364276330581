
interface Filter {
  lettergrade: string[];
  gradeLevel: string[];
  earlyPerformance: string[];
  parish: string;
  extraFilters: string[];
  starRating: number;
  schoolNameFilter: string;
}
import { enumToMap } from "@/models/schoolfinder.enums";
import { Component, Vue, Watch, Mixins } from "vue-property-decorator";
import { mapState } from "vuex";
import {
  School,
  SchoolSearchCriteria,
  SchoolSearchModel,
} from "../models/interface";
import Typeahead from "../components/Typeahead.vue";
import { TypeaheadModel } from "@/models/typeahead.model";
import GoogleMapMixin from "../mixins/google-map.mixin";
import { concat, constant, rearg, times, trim } from "lodash";
import { warn } from "vue-class-component/lib/util";
import { EventBus } from "@/event-bus";
import AppNotification from "@/components/AppNotification.vue";
import { BIconYoutube } from "bootstrap-vue";
import { CompareFav } from "@/mixins/compare-fav.mixin";
import CommonMixin from "@/mixins/common.mixin";
import { ProfileType, profileTypeArray } from "@/models/schoolfinder.enums";

import { elements } from "vue-meta/types/vue-meta";
import { Filler } from "chart.js";
import _ from "lodash";
import { metaData } from "@/assets/js/common-meta-info";
import { setMetaInfo } from "@/assets/js/meta-info-config";
@Component({
  components: { "type-ahead": Typeahead, AppNotification },
  computed: { ...mapState("app", ["schoolSearchResults"]) },
})
export default class SearchResults extends Mixins(
  GoogleMapMixin,
  CompareFav,
  CommonMixin
) {
  schoolSearchResults!: School[];

  expandFilters = false;
  //public totalRows = 1;
  public perPage = 10;
  public schoolNameFilter = "";
  public currentPage = 1;
  private nextSortOrder = "asc";
  public listOfSchools: School[] = [];
  public masterListOfSchools: School[] = [];
  public schoolSearchCriteria = {} as SchoolSearchCriteria;
  public address1 = "";
  public schoolSearchModel = new SchoolSearchModel();
  public fetcAdresshUrl = "School/GetGoogleAddress?q=" + this.address1;

  ProfileType = ProfileType;
  profileTypeArray = profileTypeArray;

  public sortColumn = "grade-served";
  public filterButtonVisible = false;
  public filterActive = false;
  private previousFilter = {} as Filter;
  public pageOptions = [
    { value: 5, text: "Show 5 per page" },
    { value: 10, text: "Show 10 per page" },
    { value: 20, text: "Show 20 per page" },
    { value: 30, text: "Show 30 per page" },
    { value: 40, text: "Show 40 per page" },
    { value: 50, text: "Show 50 per page" },
    { value: -1, text: "Show All" },
  ];
  sortIconMap = new Map([
    ["distance", "sort-none"],
    ["grade-served", "sort-none"],
    ["name", "sort-none"],
    ["sitescore", "sort-none"],
  ]);

  sortSchoolGrid(parameter = "") {
    this.sortColumn = parameter;
    this.sortIconMap = new Map([
      ["distance", "sort-none"],
      ["grade-served", "sort-none"],
      ["name", "sort-none"],
      ["sitescore", "sort-none"],
    ]);
    switch (this.sortColumn.toLowerCase()) {
      case "distance":
        this.listOfSchools = this.listOfSchools.sort((a, b) => {
          const da = +(a.distance || "0");
          const db = +(b.distance || "0");
          return this.nextSortOrder == "asc" ? db - da : da - db;
        });
        this.sortIconMap.set(
          "distance",
          this.nextSortOrder == "asc" ? "sort-asc" : "sort-desc"
        );
        break;
      case "grade-served":
        this.listOfSchools = this.listOfSchools.sort((a, b) => {
          const ga = (a.sps || 0);
          const gb = (b.sps || 0);
          if (this.nextSortOrder == "asc") {
            return ga > gb ? -1 : 1;
          } else {
            return gb > ga ? -1 : 1;
          }
        });
        this.sortIconMap.set(
          "grade-served",
          this.nextSortOrder == "asc" ? "sort-asc" : "sort-desc"
        );
        break;
      case "sitescore":
        this.listOfSchools = this.listOfSchools.sort((a, b) =>
          a.profileType > b.profileType ? 1 : -1
        );
        this.listOfSchools = this.listOfSchools.sort((a, b) => {
          const sa = Number(a.siteScore) || 0;
          const sb = Number(b.siteScore) || 0;

          if (this.nextSortOrder == "asc") {
            return sa > sb ? -1 : 1;
          } else {
            return sb > sa ? -1 : 1;
          }
        });
        this.sortIconMap.set(
          "sitescore",
          this.nextSortOrder == "asc" ? "sort-asc" : "sort-desc"
        );
        break;
      case "name":
        this.listOfSchools = this.listOfSchools.sort((a, b) => {
          const na = (a.name || "").toLowerCase();
          const nb = (b.name || "").toLowerCase();
          if (this.nextSortOrder == "asc") {
            return na < nb ? -1 : 1;
          } else {
            return nb < na ? -1 : 1;
          }
        });
        this.sortIconMap.set(
          "name",
          this.nextSortOrder == "asc" ? "sort-asc" : "sort-desc"
        );
        break;
    }
    this.nextSortOrder = this.nextSortOrder == "asc" ? "desc" : "asc";
    this.afterPageChange();
  }
  afterPageChange() {
    setTimeout(() => {
      this.removeAllMarkers();

      this.addMarkerOnMap(this.schoolList);
      window.scrollTo(0, 0);
    }, 1000);
  }

  onResize() {
    console.log("resize");
    const f = this.$refs.showFilter as Element;
    const style = window.getComputedStyle(f);
    this.filterButtonVisible = style.display != "none";
    console.log("resize", this.filterButtonVisible);

    if (!this.filterButtonVisible) {
      const map = this.$refs.mapContainer as Element;
      const mapStyles = window.getComputedStyle(map);
      const filterContainer = this.$refs.filterContainer as Element;
      const filterStyles = window.getComputedStyle(filterContainer);
      const resultsContainer = this.$refs.resultsContainer as Element;
      const resultsStyles = window.getComputedStyle(resultsContainer);
      const filterButton = this.$refs.showFilter as Element;
      const filterButtonStyles = window.getComputedStyle(filterButton);
      map.classList.remove("d-none");
      map.classList.add("d-block");

      filterContainer.classList.add("d-flex");
      filterContainer.classList.remove("d-none");
      resultsContainer.classList.remove("d-none");
      resultsContainer.classList.add("d-block");

      this.filterActive = false;
    } else {
      if (!this.filterActive) {
        const filterContainer = this.$refs.filterContainer as Element;
        const filterStyles = window.getComputedStyle(filterContainer);
        filterContainer.classList.remove("d-flex");
        filterContainer.classList.add("d-none");
      }
    }

    this.mobileView = window.innerWidth <= 576;
    this.tabletView = window.innerWidth > 576;
  }

  private resetFilter() {
    this.currentFilters = { ...this.previousFilter };
    const gradesMapInv = new Map(
      [...this.gradesMap.entries()].map(([key, value]) => [value, key])
    );

    this.lettergrade = this.previousFilter.lettergrade.map(
      (e) => gradesMapInv.get(e) as string
    );
    this.applyFilters();
  }

  private applyFilters() {
    const map = this.$refs.mapContainer as Element;
    const mapStyles = window.getComputedStyle(map);
    const filterContainer = this.$refs.filterContainer as Element;
    const filterStyles = window.getComputedStyle(filterContainer);
    const resultsContainer = this.$refs.resultsContainer as Element;
    const resultsStyles = window.getComputedStyle(resultsContainer);
    const filterButton = this.$refs.showFilter as Element;
    const filterButtonStyles = window.getComputedStyle(filterButton);
    map.classList.remove("d-none");
    map.classList.add("d-block");
    filterContainer.classList.remove("d-flex");
    filterContainer.classList.add("d-none");
    resultsContainer.classList.remove("d-none");
    resultsContainer.classList.add("d-block");
    this.filterActive = false;
  }

  private showFilters() {
    const map = this.$refs.mapContainer as Element;
    const mapStyles = window.getComputedStyle(map);
    const filterContainer = this.$refs.filterContainer as Element;
    const filterStyles = window.getComputedStyle(filterContainer);
    const resultsContainer = this.$refs.resultsContainer as Element;
    const resultsStyles = window.getComputedStyle(resultsContainer);
    const filterButton = this.$refs.showFilter as Element;
    const filterButtonStyles = window.getComputedStyle(filterButton);
    this.filterButtonVisible = filterButtonStyles.display != "none";

    if (this.filterActive && this.filterButtonVisible) {
      map.classList.remove("d-none");
      map.classList.add("d-block");
      filterContainer.classList.remove("d-flex");
      filterContainer.classList.add("d-none");
      resultsContainer.classList.remove("d-none");
      resultsContainer.classList.add("d-block");
      this.filterActive = false;
    } else {
      map.classList.remove("d-block");
      map.classList.add("d-none");
      resultsContainer.classList.remove("d-block");
      resultsContainer.classList.add("d-none");
      filterContainer.classList.remove("d-none");
      filterContainer.classList.add("d-flex");
      this.previousFilter = { ...this.currentFilters };
      this.filterActive = true;
    }
  }

  applySchoolNameFilters() {
    if (this.schoolNameFilter !== "") {
      this.listOfSchools = this.masterListOfSchools;
      this.listOfSchools = this.listOfSchools.filter(
        (x) =>
          x.name.toLowerCase().indexOf(this.schoolNameFilter.toLowerCase()) > -1
      );
      this.afterPageChange();
    }
  }
  private applyExtrafilters(filterType: string) {
    console.log(filterType);
  }

  get isToggleFilterVisible(): boolean {
    const f = this.$refs.showFilter as Element;
    const style = window.getComputedStyle(f);
    console.log(style.display);
    return false;
  }
  navigateToSchoolDetail(school: School, schoolIndex: number) {
    let url;
    if (this.earlyChildhood(school))
      url = "/schools/" + school.entityId + "/ec-school-about/" + school.name;
    else
      url = "/schools/" + school.entityId + "/about-our-school/" + school.name;

    console.log(url);
    school.schoolIndex = schoolIndex;
    sessionStorage.setItem("selected-school", JSON.stringify(school));
    this.$router.push(url);
  }

  get pageCount(): number | string | undefined | null {
    if (this.listOfSchoolsFormatted && this.listOfSchoolsFormatted.length > 0) {
      const len = this.listOfSchoolsFormatted.length;
      const recordsPerPage =
        this.perPage == -1 ? this.schoolList.length : this.perPage;
      if (len < this.perPage) {
        return this.currentPage;
      } else {
        if (recordsPerPage <= 0) {
          return 1;
        }
        const pgcount = Math.ceil(len / recordsPerPage);
        return pgcount;
      }
    } else {
      return this.currentPage;
    }
  }

  get totalRows() {
    return this.listOfSchoolsFormatted.length;
  }

  async mounted() {
    setMetaInfo({ title: "School / Center Search Results" });
    // this.pageOptions[this.pageOptions.length - 1].value = (
    //   this.listOfSchools as []
    // ).length;

    const searchCriteraObj = this.$store.getters["app/getschoolSearchCriteria"];
    // TODO delete after test
    if (
      searchCriteraObj &&
      searchCriteraObj.latitude &&
      searchCriteraObj.longitude
    ) {
      this.schoolSearchCriteria = searchCriteraObj;
    } /*else {
      this.schoolSearchCriteria = {
        searchText: "test",
        latitude: 29.95106579999999,
        longitude: -90.071532,
        distance: 5,
        gradelevel: "Kindergarten",
      } as SchoolSearchCriteria;
    }*/
    this.startGoogleMap("map");
    await this.getSearchResults();
    this.selectedTypeaheadModel = new TypeaheadModel(
      null,
      "",
      "",
      "",
      null,
      null,
      ""
    );
    this.currentFilters = {
      lettergrade: [],
      gradeLevel: [],
      earlyPerformance: [],
      parish: "",
      extraFilters: [],
      starRating: 0,
      schoolNameFilter: "",
    };
    this.$nextTick(function () {
      const currentDate = new Date();
      console.log(
        "Search - End:" +
          currentDate +
          ": milliseconds -" +
          currentDate.getMilliseconds()
      );
    });
    window.addEventListener("resize", this.onResize);

    this.mobileView = window.innerWidth <= 576;
    this.tabletView = window.innerWidth > 576;
  }
  destroyed() {
    window.removeEventListener("resize", this.onResize);
  }

  async getSearchResults() {
    await this.$store.dispatch(
      "app/getSchoolSearchResults",
      this.schoolSearchCriteria
    );
    this.masterListOfSchools = await this.$store.state.app.schoolSearchResults;
    // this.masterListOfSchools.forEach((element) => {
    //    element.isFavorite = false;
    // });

    this.markCompareFavSchools();
    this.listOfSchools = this.masterListOfSchools;
    setTimeout(() => {
      this.removeAllMarkers();
      this.addMarkerOnMap(this.schoolList);
    }, 1000);
    if (!_.isEmpty(this.schoolSearchCriteria)) {
      this.updateCurrentlySearchedLocationMarker(this.schoolSearchCriteria);
    } else {
      this.setCurrentlySearchedLocationMarker(this.schoolSearchCriteria);
    }
  }

  markCompareFavSchools() {
    const compareList = this.getObjectFromSessionStorage("compareList");
    if (this.isNotEmpty(compareList)) {
      compareList.forEach((element: School) => {
        if (
          this.masterListOfSchools.filter(
            (s) => s.entityId == element.entityId
          )[0]
        ) {
          this.masterListOfSchools.filter(
            (s) => s.entityId == element.entityId
          )[0].isToCompare = true;
        }
      });
    }

    const favList = this.getObjectFromSessionStorage("favList");
    if (this.isNotEmpty(favList)) {
      favList.forEach((element: School) => {
        if (
          this.masterListOfSchools.filter(
            (s) => s.entityId == element.entityId
          )[0]
        ) {
          this.masterListOfSchools.filter(
            (s) => s.entityId == element.entityId
          )[0].isFavorite = true;
        }
      });
    }
  }

  get schoolList() {
    let finalList: School[] = [];
    if (this.perPage == -1) {
      finalList = this.listOfSchoolsFormatted;
    } else {
      finalList = this.listOfSchoolsFormatted.slice(
        (this.currentPage - 1) * this.perPage,
        this.currentPage * this.perPage
      );
    }
    return finalList ?? this.listOfSchoolsFormatted;
  }

  toggleExpand() {
    this.expandFilters = !this.expandFilters;
  }

  addSchoolToFavList(school: School, index: number) {
    this.schoolList.filter((s) => s.entityId == school.entityId)[0].isFavorite =
      true;
    this.addToFavList(school, index);
    setTimeout(() => {
      this.updateMarker(index - 1, school);
    }, 100);
  }

  removeSchoolFromFavList(school: School, index: number) {
    this.schoolList.filter((s) => s.entityId == school.entityId)[0].isFavorite =
      false;
    this.removeFromFavList(school);
    setTimeout(() => {
      this.updateMarker(index - 1, school);
    }, 100);
  }

  addSchoolToCompareList(school: School, index: number) {
    if (this.latestCompareCount() == this.maxNumberOfComparePossible) {
      this.$bvToast.show("maxCompareLimit");
    } else {
      this.schoolList.filter(
        (s) => s.entityId == school.entityId
      )[0].isToCompare = true;
      this.addToCompareList(school, index);
    }
  }

  removeSchoolFromCompareList(school: School) {
    this.schoolList.filter(
      (s) => s.entityId == school.entityId
    )[0].isToCompare = false;
    this.removeFromCompareList(school);
  }

  // filter start */
  public selectedTypeaheadModel: TypeaheadModel | null = null;
  lettergrade: string[] = [];
  milesaway: number[] = [5];
  ageAndlevel: string[] = [];
  public currentFilters: Filter = {
    lettergrade: [""],
    gradeLevel: [""],
    earlyPerformance: [],
    parish: "",
    extraFilters: [""],
    starRating: 0,
    schoolNameFilter: "",
  }; //
  earlyPerformance: string[] = [];

  starRating = 0;

  showStarRating = false;

  showExtraFilters = false;
  gradesMap = new Map([
    ["6", "N"],
    ["1", "A"],
    ["2", "B"],
    ["3", "C"],
    ["4", "D"],
    ["5", "F"],
  ]);

  parish = "";
  maxMilesAway = 5;

  mobileView = false;
  tabletView = false;

  public data = [];
  public fetchUrl = "School/parish?q=" + this.parish;
  get ShowExtraFilters() {
    const show = this.currentFilters.gradeLevel.length > 0;
    if (!show) this.currentFilters.extraFilters = [];
    return show;
  }

  get ShowAfterCare() {
    const show = [
      "kg",
      "gradeOne",
      "gradeTwo",
      "gradeThree",
      "gradeFour",
      "gradeFive",
      "gradeSix",
      "gradeSeven",
      "gradeEight",
      "gradeNine",
      "gradeTen",
      "gradeEleven",
      "gradeTwelve",
    ].some((e) => this.currentFilters.gradeLevel.includes(e));
    if (!show) {
      this.currentFilters.extraFilters =
        this.currentFilters.extraFilters.filter((s) => {
          return ["athletics", "clubs", "tutoring"].indexOf(s) < 0;
        });
    }
    return show;
  }
  get ShowStartRating() {
    const show = ["ageInfant", "ageToddler", "agePreK"].some((e) =>
      this.currentFilters.gradeLevel.includes(e)
    );
    if (!show) this.setStarRating(0);
    return show;
  }
  private setStarRating(rating: number) {
    this.starRating = this.starRating == rating ? --rating : rating;
    this.currentFilters.starRating = this.starRating;
  }

  async mileAwayClick($event: any) {
    if ($event.target.checked) {
      switch (+$event.target.value) {
        case 5:
          this.milesaway = [5];
          break;
        case 10:
          this.milesaway = [5, 10];
          break;
        case 20:
          this.milesaway = [5, 10, 20];
          break;
        default:
          this.milesaway = [5];
          break;
      }
    } else {
      switch (+$event.target.defaultValue) {
        case 5:
          this.milesaway = [];
          break;
        case 10:
          this.milesaway = [5];
          break;
        case 20:
          this.milesaway = [5, 10];
          break;
        default:
          this.milesaway = [];
          break;
      }
    }

    const maxMiles =
      this.milesaway && this.milesaway.length > 0
        ? this.milesaway.reduce(
            (accumulator, currentValue) => Math.max(accumulator, currentValue),
            0
          )
        : 5;

    this.maxMilesAway = maxMiles;

    await this.milesawayFilter(maxMiles);

    //reapply filters
    this.filter(this.currentFilters, null);
    await this.changeMapAfterFilter();
  }

  async changeMapAfterFilter() {
    await this.removeAllMarkers();
    this.addMarkerOnMap(this.schoolList);
    this.updateCurrentlySearchedLocationMarker(this.schoolSearchCriteria);
  }

  private letterGradeClick($event: any) {
    this.$nextTick(async () => {
      const checkBox = $event.target;
      let val = checkBox.value;
      // easier if for every click, calcuulations start over
      this.lettergrade = [];
      // if a box is unchecked select up to the next lower grade
      if (!checkBox.checked) {
        val = (+val - 1).toString();
      }
      for (let i = 1; i <= +val; i++) {
        const ix = i.toString();
        if (this.lettergrade.indexOf(ix) < 0) {
          this.lettergrade.push(ix);
        }
      }

      this.currentFilters.lettergrade = this.lettergrade.map(
        (e) => this.gradesMap.get(e) as string
      );
      console.log(this.currentFilters.lettergrade);
      await this.changeMapAfterFilter();
    });
  }

  async milesawayFilter(miles: number) {
    this.currentPage = 1;
    this.schoolSearchCriteria.distance = miles;
    this.sortSchoolGrid(this.sortColumn);

    await this.$store.dispatch(
      "app/getSchoolSearchResults",
      this.schoolSearchCriteria
    );

    this.listOfSchools = this.schoolSearchResults;
  }

  private resetParish() {
    this.currentPage = 1;
    this.currentFilters.parish = "";
  }

  @Watch("currentFilters", { deep: true })
  private filter(newValue: any, oldValue: any) {
    // let searchlist = this.masterListOfSchools;
    let searchlist = this.schoolSearchResults;

    for (const [key, value] of Object.entries(newValue)) {
      if (key && value) {
        switch (key.toLowerCase()) {
          case "lettergrade": {
            let lettergrades = (value as string[]) || [];
            lettergrades = lettergrades.filter(Boolean);
            if (!lettergrades.length) continue;
            searchlist = [...this.filterLetergrade(searchlist, lettergrades)];
            break;
          }
          case "gradelevel": {
            let gradeLevels = (value as string[]) || [];
            gradeLevels = gradeLevels.filter(Boolean);

            if (!gradeLevels.length) continue;
            searchlist = [...this.filterGradeLevel(searchlist, gradeLevels)];
            break;
          }
          case "earlyperformance": {
            let performanceFilter = (value as string[]) || [];
            performanceFilter = performanceFilter.filter(Boolean);
            if (!performanceFilter.length) continue;
            searchlist = [
              ...this.filterPerformance(searchlist, performanceFilter),
            ];
            break;
          }
          case "starrating": {
            const starratingFilter = +((value as string) || ("" as string));
            if (starratingFilter <= 0) continue;
            searchlist = [
              ...this.filterStarrating(searchlist, starratingFilter),
            ];
            break;
          }
          case "parish": {
            const parish = value as string;
            if (!parish) continue;
            searchlist = [...this.filterParish(searchlist, parish)];
            break;
          }
          case "extrafilters": {
            let extraFilters = (value as string[]) || [];
            extraFilters = extraFilters.filter(Boolean);
            if (!extraFilters.length) continue;
            searchlist = [...this.filterExtra(searchlist, extraFilters)];
            break;
          }
          case "schoolnamefilter": {
            const schoolName = value as string;
            if (!schoolName) continue;
            searchlist = this.filterSchoolName(searchlist, schoolName);
            break;
          }
        }
      }
    }
    this.currentPage = 1;
    this.listOfSchools = searchlist;
    this.sortSchoolGrid(this.sortColumn);
  }

  typeaheadItemSelectedAddress(model: TypeaheadModel) {
    this.typeaheadItemSelected(model);
  }

  typeaheadItemSelectedParish(model: TypeaheadModel) {
    if (model) {
      this.currentFilters.parish = model.text;
    }

    this.typeaheadItemSelected(model);
  }

  typeaheadItemSelected(model: TypeaheadModel) {
    this.selectedTypeaheadModel = model;

    const searchCriteraObj = this.$store.getters["app/getschoolSearchCriteria"];

    this.$store
      .dispatch("app/getLatLng", model.id)
      .then(async (result: any) => {
        this.schoolSearchCriteria = {
          searchText: model.text,
          entityType: model.entityType,
          latitude: result.lat,
          longitude: result.lng,
          distance: this.maxMilesAway,
          gradelevel: searchCriteraObj.gradelevel,
        };

        this.$store.commit(
          "app/setschoolSearchCriteria",
          this.schoolSearchCriteria
        );
        //this.removeAllMarkers();
        await this.getSearchResults();
        /* setTimeout(() => {
        this.addMarkerOnMap(this.schoolList);
        this.updateCurrentlySearchedLocationMarker(this.schoolSearchCriteria);
      }, 1000); */

        // const url = "/schools";
        //console.log(url);
        //this.$router.push(url);
        // window.location.reload();
      });
  }

  private mapGradeLevel(grade: string): string {
    const grades = new Map([
      ["ageInfant", "Infant (Under 1)"],
      ["ageToddler", "Toddler (ages 1-2)"],
      ["agePreK", "PK (ages 3-4)"],
      ["kg", "Kindergarten"],
      ["gradeSix", "Grade 6"],
      ["gradeFive", "Grade 5"],
      ["gradeFour", "Grade 4"],
      ["gradeThree", "Grade 3"],
      ["gradeTwo", "Grade 2"],
      ["gradeOne", "Grade 1"],
      ["gradeSeven", "Grade 7"],
      ["gradeEight", "Grade 8"],
      ["gradeNine", "Grade 9"],
      ["gradeTen", "Grade 10"],
      ["gradeEleven", "Grade 11"],
      ["gradeTwelve", "Grade 12"],
    ]);
    return grades.get(grade) || "";
  }

  private *filterLetergrade(currentList: School[], filters: string[]) {
    if (filters && filters.length) {
      for (const school of currentList) {
        const grade = school.gradeLetter || "N";
        if (filters.indexOf(grade.toUpperCase()) >= 0) yield school;
      }
    }
  }

  private *filterGradeLevel(currentList: School[], filters: string[]) {
    if (filters && filters.length) {
      for (const school of currentList) {
        const gradesServed = (school.gradesServed || "").toLowerCase();
        const isGradeServed = filters.some((f) => {
          const matchStr = `${this.mapGradeLevel(f).toLowerCase()},`;
          return gradesServed.indexOf(matchStr) >= 0;
        });
        if (isGradeServed) yield school;
      }
    }
  }

  private *filterPerformance(currentList: School[], filters: string[]) {
    if (filters && filters.length) {
      for (const school of currentList) {
        const siteRating = (school.siteRating || "").toLowerCase();
        const isRated = filters.some((f) => siteRating === f);
        if (isRated) yield school;
      }
    }
  }
  private *filterStarrating(currentList: School[], filter: number) {
    if (filter) {
      for (const school of currentList) {
        const starRating = +(school.starRating || "0").charAt(0);

        if (starRating === filter) {
          yield school;
        }
      }
    }
  }
  private *filterParish(currentList: School[], filter: string) {
    if (filter) {
      for (const school of currentList) {
        const parish = (school.districtParishName || "0").toLowerCase();

        if (parish === filter.toLowerCase()) {
          yield school;
        }
      }
    }
  }

  private *filterExtra(currentList: School[], filters: string[]) {
    if (filters && filters.length) {
      for (const school of currentList) {
        const offerings = [
          school.alternative,
          school.magnet,
          school.charter,
          school.scholarship,
          school.traditionalPublic,
          school.athletics,
          school.clubs,
          school.stem,
          school.tutoring,
          school.artsOffered,
          school.eedDanceOffered,
          school.danceOffered,
          school.eedForeignLangOffered,
          school.foreignLangOffered,
          school.eedGiftedPrograms,
          school.giftedPrograms,
          school.eedMusicOffered,
          school.theatreOffered,
          school.musicOffered,
        ].map((e) => (e || "").toLowerCase().trim());

        const isOfferd = filters.every((f) =>
          offerings.includes(f.toLowerCase())
        );
        if (isOfferd) yield school;
      }
    }
  }
  private filterSchoolName(
    currentList: School[],
    schoolName: string
  ): School[] {
    let result;
    if (schoolName) {
      result = currentList.filter(
        (x) => x.name.toLowerCase().indexOf(schoolName.toLowerCase()) > -1
      );
    } else {
      result = currentList;
    }

    return result;
  }

  // filter end
  created() {
    const currentDate = new Date();
    console.log(
      "Search - End:" +
        currentDate +
        ": milliseconds -" +
        currentDate.getMilliseconds()
    );
  }

  get resultCardContainerBorder() {
    return {
      "border-right-1": this.tabletView,
      "border-0": this.mobileView,
    };
  }

  get resultCardK12ECLayout() {
    return {
      col: this.tabletView,
      "d-flex flex-row": this.mobileView,
    };
  }

  get schoolHeaderContainerLayout() {
    return {
      "mb-3": this.tabletView,
      "": this.mobileView,
    };
  }

  get schoolHeaderMetadataLayout() {
    return {
      "": this.tabletView,
      "flex-column": this.mobileView,
    };
  }

  get roadMilesLayout() {
    return {
      "ml-auto": this.tabletView,
      "": this.mobileView,
    };
  }

  k12Both(school: School) {
    return (
      school.profileType === this.profileTypeArray[this.ProfileType.K12].name ||
      school.profileType === this.profileTypeArray[this.ProfileType.Both].name
    );
  }

  k12(school: School) {
    return (
      school.profileType === this.profileTypeArray[this.ProfileType.K12].name
    );
  }

  earlyChildhood(school: School) {
    return (
      school.profileType ===
      this.profileTypeArray[this.ProfileType.EarlyChildhood].name
    );
  }

  both(school: School) {
    return (
      school.profileType === this.profileTypeArray[this.ProfileType.Both].name
    );
  }

  get listOfSchoolsFormatted() {
    const result: School[] = [];
    this.listOfSchools.forEach((item, index) => {
      item.id = index + 1;
      result.push(item);
    });
    return result;
  }

  clearFilters() {
    this.clearCurrentFilters();
    this.lettergrade = [];
    this.milesaway = [5];
  }

  clearCurrentFilters() {
    this.currentFilters = {
      lettergrade: [],
      gradeLevel: [],
      earlyPerformance: [],
      parish: "",
      extraFilters: [],
      starRating: 0,
      schoolNameFilter: "",
    };
  }
}
