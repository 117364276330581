export class TypeaheadModel {
    public constructor(
        public id: any | null,
        public text: string,
        public type: string,
        public entityType: string,
        public lat: any | null,
        public lng: any | null,
        public profileType: string,
    ) { }
}